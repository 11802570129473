@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {

	/* cyrillic-ext */
	@font-face {
		font-family: "Plus Jakarta Sans";
		font-style: normal;
		font-weight: 200 800;
		font-display: swap;
		src: url('/public/assets/fonts/PlusJakartaSans-cyrillic-ext.woff2') format("woff2");
		unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF,
			U+A640-A69F, U+FE2E-FE2F;
	}

	/* vietnamese */
	@font-face {
		font-family: "Plus Jakarta Sans";
		font-style: normal;
		font-weight: 200 800;
		font-display: swap;
		src: url('/public/assets/fonts/PlusJakartaSans-vietnamese.woff2') format("woff2");
		unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
			U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309,
			U+0323, U+0329, U+1EA0-1EF9, U+20AB;
	}

	/* latin-ext */
	@font-face {
		font-family: "Plus Jakarta Sans";
		font-style: normal;
		font-weight: 200 800;
		font-display: swap;
		src: url('/public/assets/fonts/PlusJakartaSans-latin-ext.woff2') format("woff2");
		unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
			U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
			U+A720-A7FF;
	}

	/* latin */
	@font-face {
		font-family: "Plus Jakarta Sans";
		font-style: normal;
		font-weight: 200 800;
		font-display: swap;
		src: url('/public/assets/fonts/PlusJakartaSans-latin.woff2') format("woff2");
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
			U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
			U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	*,
	*:before,
	*:after {
		@apply box-border !outline-transparent outline-none;
	}

	a,
	button,
	input,
	select,
	textarea {
		@apply cursor-pointer transition;
	}
}

@layer components {

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}

	.ReactTable {
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		border: 0px solid transparent;
		border-radius: 1.25rem;
		background-color: #fff;
	}

	.ReactTable * {
		box-sizing: border-box
	}

	.ReactTable .rt-table {
		-webkit-box-flex: 1;
		-ms-flex: auto 1;
		flex: auto 1;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		width: 100%;
		border-collapse: collapse;
		overflow: auto
	}

	.ReactTable .rt-thead {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.ReactTable .rt-thead.-headerGroups {
		background: rgba(0, 0, 0, 0.03);
		border-bottom: 1px solid rgba(0, 0, 0, 0.05)
	}

	.ReactTable .rt-thead.-filters {
		border-bottom: 0px solid rgba(0, 0, 0, 0.05);
	}

	.ReactTable .rt-thead.-filters input,
	.ReactTable .rt-thead.-filters select {
		border: 1px solid rgba(0, 0, 0, 0.1);
		background: #fff;
		padding: 10px 20px;
		font-size: inherit;
		border-radius: 1.25rem;
		font-weight: normal;
		height: 50px;
		outline: none
	}

	.ReactTable .rt-thead.-filters .rt-th {
		border-right: 1px solid rgba(0, 0, 0, 0.02)
	}

	.ReactTable .rt-thead .rt-tr {
		text-align: center;
		background-color: white
	}

	.ReactTable .rt-thead .rt-th,
	.ReactTable .rt-thead .rt-td {
		padding: 10px 10px 20px 10px;
		line-height: normal;
		position: relative;
		border-right: 1px solid rgba(0, 0, 0, 0.05);
		transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
		box-shadow: inset 0 0 0 0 transparent;
	}

	.ReactTable .rt-thead .rt-th.-sort-asc,
	.ReactTable .rt-thead .rt-td.-sort-asc {
		box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6)
	}

	.ReactTable .rt-thead .rt-th.-sort-desc,
	.ReactTable .rt-thead .rt-td.-sort-desc {
		box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6)
	}

	.ReactTable .rt-thead .rt-th.-cursor-pointer,
	.ReactTable .rt-thead .rt-td.-cursor-pointer {
		padding: 20px;
		cursor: pointer
	}

	.ReactTable .rt-thead .rt-th:last-child,
	.ReactTable .rt-thead .rt-td:last-child {
		border-right: 0
	}

	.ReactTable .rt-thead .rt-resizable-header {
		overflow: visible;
	}

	.ReactTable .rt-thead .rt-resizable-header:last-child {
		overflow: hidden
	}

	.ReactTable .rt-thead .rt-resizable-header-content {
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: bold;
		color: #000
	}

	.ReactTable .rt-thead .rt-header-pivot {
		border-right-color: #f7f7f7
	}

	.ReactTable .rt-thead .rt-header-pivot:after,
	.ReactTable .rt-thead .rt-header-pivot:before {
		left: 100%;
		top: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none
	}

	.ReactTable .rt-thead .rt-header-pivot:after {
		border-color: rgba(255, 255, 255, 0);
		border-left-color: #fff;
		border-width: 8px;
		margin-top: -8px
	}

	.ReactTable .rt-thead .rt-header-pivot:before {
		border-color: rgba(102, 102, 102, 0);
		border-left-color: #f7f7f7;
		border-width: 10px;
		margin-top: -10px
	}

	.ReactTable .rt-tbody {
		padding: 0px 10px;
		-webkit-box-flex: 99999;
		-ms-flex: 99999 1 auto;
		flex: 99999 1 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		overflow: hidden;
	}

	.ReactTable .rt-tbody .rt-tr-group {
		border-bottom: solid 1px transparent;
	}

	.ReactTable .rt-tbody .rt-tr-group:last-child {
		border-bottom: 0
	}

	.ReactTable .rt-tbody .rt-td {
		border-right: 1px solid transparent;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #2c2c2c;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.ReactTable .rt-tbody .rt-td:last-child {
		border-right: 0
	}

	.ReactTable .rt-tbody .rt-expandable {
		cursor: pointer
	}

	.ReactTable .rt-tr-group {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch
	}

	.ReactTable .rt-tr {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex
	}

	.ReactTable .rt-th,
	.ReactTable .rt-td {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 0px;
		flex: 1 0 0;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding: 7px 5px;
		transition: .3s ease;
		transition-property: width, min-width, padding, opacity;
	}

	.ReactTable .rt-th.-hidden,
	.ReactTable .rt-td.-hidden {
		width: 0 !important;
		min-width: 0 !important;
		padding: 0 !important;
		border: 0 !important;
		opacity: 0 !important
	}

	.ReactTable .rt-expander {
		display: inline-block;
		position: relative;
		margin: 0;
		color: transparent;
		margin: 0 10px;
	}

	.ReactTable .rt-expander:after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%) rotate(-90deg);
		transform: translate(-50%, -50%) rotate(-90deg);
		border-left: 5.04px solid transparent;
		border-right: 5.04px solid transparent;
		border-top: 7px solid rgba(0, 0, 0, 0.8);
		transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
		cursor: pointer
	}

	.ReactTable .rt-expander.-open:after {
		-webkit-transform: translate(-50%, -50%) rotate(0);
		transform: translate(-50%, -50%) rotate(0)
	}

	.ReactTable .rt-resizer {
		display: inline-block;
		position: absolute;
		width: 36px;
		top: 0;
		bottom: 0;
		right: -18px;
		cursor: col-resize;
		z-index: 10
	}

	.ReactTable .rt-tfoot {
		-webkit-box-flex: 1;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
	}

	.ReactTable .rt-tfoot .rt-td {
		border-right: 1px solid rgba(0, 0, 0, 0.05);
	}

	.ReactTable .rt-tfoot .rt-td:last-child {
		border-right: 0
	}

	.ReactTable.-striped .rt-tr.-odd {
		background: rgba(0, 0, 0, 0.02);
		border-radius: 1.25rem
	}

	.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
		background-color: none;
	}

	.ReactTable .-pagination {
		z-index: 1;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 3px 10px;
		margin-top: 40px;
		height: auto;
	}

	.ReactTable .-pagination input,
	.ReactTable .-pagination select {
		border: 1px solid rgba(0, 0, 0, 0.1);
		background: #fff;
		padding: 0px 20px;
		font-size: inherit;
		border-radius: 1.5rem;
		font-weight: normal;
		outline: none;
		height: 50px;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: 0;
	}

	.ReactTable .-pagination .-btn {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: block;
		width: 100%;
		height: 100%;
		border: 0;
		border-radius: 1.5rem;
		padding: 6px;
		font-size: 1em;
		color: #fff;
		background: #3AAAE7;
		transition: all .1s ease;
		cursor: pointer;
		outline: none;
	}

	.ReactTable .-pagination .-btn[disabled] {
		opacity: .6;
		cursor: default
	}

	.ReactTable .-pagination .-btn:not([disabled]):hover {
		background: rgba(0, 0, 0, 0.3);
		color: #fff
	}

	.ReactTable .-pagination .-previous,
	.ReactTable .-pagination .-next {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		text-align: center
	}

	.ReactTable .-pagination .-center {
		-webkit-box-flex: 1.5;
		-ms-flex: 1.5;
		flex: 1.5;
		text-align: center;
		margin-bottom: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: distribute;
		justify-content: space-around
	}

	.ReactTable .-pagination .-pageInfo {
		display: inline-block;
		margin: 3px 10px;
		white-space: nowrap
	}

	.ReactTable .-pagination .-pageJump {
		display: inline-block;
	}

	.ReactTable .-pagination .-pageJump input {
		width: 70px;
		text-align: center;
	}

	.ReactTable .-pagination .-pageJump input {
		-moz-appearance: textfield;
	}

	.ReactTable .-pagination .-pageJump input::-webkit-inner-spin-button,
	.ReactTable .-pagination .-pageJump input::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.ReactTable .-pagination .-pageSizeOptions {
		margin: 3px 10px
	}

	.ReactTable .rt-noData {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		background: rgba(255, 255, 255, 0.8);
		transition: all .3s ease;
		z-index: 1;
		pointer-events: none;
		padding: 20px;
		color: rgba(0, 0, 0, 0.5);
		border-radius: 1.25rem
	}

	.ReactTable .-loading {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.8);
		transition: all .3s ease;
		z-index: -1;
		opacity: 0;
		pointer-events: none;
	}

	.ReactTable .-loading>div {
		position: absolute;
		display: block;
		text-align: center;
		width: 100%;
		top: 50%;
		left: 0;
		font-size: 15px;
		color: rgba(0, 0, 0, 0.6);
		-webkit-transform: translateY(-52%);
		transform: translateY(-52%);
		transition: all .3s cubic-bezier(.25, .46, .45, .94)
	}

	.ReactTable .-loading.-active {
		opacity: 1;
		z-index: 2;
		pointer-events: all;
	}

	.ReactTable .-loading.-active>div {
		-webkit-transform: translateY(50%);
		transform: translateY(50%)
	}

	.ReactTable .rt-resizing .rt-th,
	.ReactTable .rt-resizing .rt-td {
		transition: none !important;
		cursor: col-resize;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none
	}
}



.input-field {
	@apply w-full flex flex-col;
}

.input-field input,
.input-field textarea {
	@apply w-full border border-solid focus:outline-teal-500 rounded-lg duration-300 px-3 py-2;
}

.input-field textarea {
	@apply min-h-[100px];
}

.input-field input[aria-invalid='true'],
.input-field textarea[aria-invalid='true'] {
	@apply border-red-500;
}

.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper {
	@apply w-[140px]
}

.css-bmoxj4-MuiList-root-MuiMultiSectionDigitalClockSection-root {
	width: 70px!important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 0.5rem 0.75rem!important;
	border-radius: 1rem;
}

fieldset.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	height: 47px;
	border-radius: 0.5rem!important;
	border-color: rgba(0, 0, 0, 0.1)!important;
}